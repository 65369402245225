<template>
  <div class="pay-page">
    <div class="breadcrumb">
      <span>首页</span>
      <img src="@/assets/images/icon-right-grey.png" alt="" />
      <span>专业鉴定</span>
      <img src="@/assets/images/icon-right-grey.png" alt="" />
      <span>支付鉴定费用</span>
    </div>
    <div class="box">
      <div class="title">
        <img src="@/assets/images/recreation-icon.png" alt="" />
        <p>支付方式</p>
      </div>
      <div class="content">
        <p class="tip">需支付商品金额为</p>
        <div class="amount">
          <text>¥</text>
          <text>{{ route.query.price }}</text>
        </div>
        <!-- <div class="timing">
          <text>支付剩余时间:</text>
          <text>15:00</text>
        </div> -->
        <a-radio-group v-model:value="value1" @change="radioChange">
          <a-radio-button value="alipay">
            <img src="@/assets/images/pay-alipay.png" alt="" />
            <text>支付宝</text>
          </a-radio-button>
          <a-radio-button value="balance">
            <img src="@/assets/images/pay-balance.png" alt="" />
            <text>余额({{ balance }})</text>
          </a-radio-button>
        </a-radio-group>
      </div>
      <div class="submit" @click="submitClick">确认支付</div>
    </div>
  </div>
  <el-dialog v-model="dialogVisible" title="" width="30%" :show-close="false">
    <div class="popup-box">
      <img src="@/assets/images/success-icon.png" alt="" />
      <p>恭喜您，支付成功</p>
      <div @click="close">填写鉴定信息</div>
    </div>
  </el-dialog>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { balancePay } from '@/api/goods'
import { userInfo } from '@/api/user'
import { ElMessage } from 'element-plus'
const route = useRoute()
const router = useRouter()
const value1 = ref('')
const balance = ref('')
const dialogVisible = ref(false)
const count = ref(null)
const timer = ref(null)
function radioChange() {
  console.log(value1)
}
function getUserInfo() {
  userInfo().then((res) => {
    if (res.code == 200) {
      balance.value = res.data.balance
    }
  })
}
function submitClick() {
  if (!value1.value) {
    ElMessage.error('请选择支付方式!')
    return
  }
  if (value1.value === 'balance') {
    balancePay({ order_no: route.query.orderNo }).then((res) => {
      if (res.code == 200) {
        dialogVisible.value = true
      } else {
        ElMessage.error(res.msg)
      }
    })
  } else {
  }
}
function close() {
  dialogVisible.value = false
  router.push({ path: '/appraisalForm', query: { type: 'pay' } })
}
onMounted(() => {
  console.log(route.query)
  getUserInfo()
})
</script>
<style lang="less" scoped>
.pay-page {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 20px;
  .breadcrumb {
    margin: 14px 0;
    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      cursor: pointer;
    }
    img {
      margin: 0 3px;
    }
    span:last-child {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      cursor: auto;
    }
  }
  .box {
    .title {
      width: 1200px;
      height: 45px;
      background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
      border-radius: 12px 12px 0px 0px;
      display: flex;
      align-items: center;
      padding-left: 18px;
      img {
        width: 20px;
        height: 15px;
      }
      p {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        margin-left: 5px;
      }
    }
    .content {
      width: 1200px;
      height: 367px;
      background: #ffffff;
      text-align: center;
      border-radius: 0 0 12px 12px;
      padding-top: 50px;
      .tip {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #b3b3b3;
      }
      .amount {
        margin-top: 12px;
        text:nth-child(1) {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ff211b;
        }
        text:nth-child(2) {
          font-size: 28px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ff211b;
        }
      }
      .timing {
        width: 200px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        background: linear-gradient(90deg, #eff8ff 0%, #eff8ff 100%);
        border-radius: 18px;
        margin: 0 auto;
        margin-top: 24px;
        text {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #06a4f9;
        }
      }
      .ant-radio-group {
        margin-top: 26px;
        .ant-radio-button-wrapper {
          width: 200px;
          height: 60px;
          line-height: 60px;
          border-radius: 8px;
          border: 2px solid #e6e6e6;
          text {
            margin-left: 6px;
          }
        }
        .ant-radio-button-wrapper:nth-child(2) {
          margin-left: 48px;
        }
        .ant-radio-button-wrapper:not(:first-child)::before {
          content: none;
        }
        .ant-radio-button-wrapper-checked {
          background: url('~@/assets/images/pay-status.png') no-repeat;
        }
      }
    }
    .submit {
      width: 302px;
      height: 51px;
      text-align: center;
      line-height: 51px;
      background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      border-radius: 12px;
      margin: 0 auto;
      margin-top: 36px;
      cursor: pointer;
    }
  }
}
.popup-box {
  text-align: center;
  p {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1a1a1a;
    margin-top: 20px;
  }
  div {
    width: 204px;
    height: 50px;
    background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
    border-radius: 26px;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    line-height: 50px;
    margin: 0 auto;
    margin-top: 28px;
    cursor: pointer;
  }
}
</style>
